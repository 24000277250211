<template>
  <div class="zoom-pdf-container">
    <div class="icon" :class="{ disabled: zoom <= 0.41 }" @click="zoomOut">
      <uds-icon
        icon-name="remove"
        :color="udsColors.udsWhite"
        :disabled="zoom <= 0.41"
        rounded
        size="small"
      />
    </div>
    <div class="percent">{{ percent }}</div>
    <div class="icon" :class="{ disabled: zoom >= 3 }" @click="zoomIn">
      <uds-icon
        icon-name="add"
        :color="udsColors.udsWhite"
        :disabled="zoom >= 3"
        rounded
        size="small"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "zoomIn" | "zoomOut", zoom: number): void;
}>();

const props = defineProps<{
  zoom: number;
}>();

const udsColors = getUdsColors();

const percent = computed<string>(() => `${Math.round(props.zoom * 100 * 100) / 100}%`);

function zoomIn() {
  if (props.zoom <= 2.9) {
    emit("zoomIn", props.zoom + 0.2);
  }
}

function zoomOut() {
  if (props.zoom >= 0.61) {
    emit("zoomOut", props.zoom - 0.2);
  }
}
</script>

<style lang="scss" scoped>
.zoom-pdf-container {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: $uds-spacing-3;
  width: 100px;
  height: 25px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: $uds-radius-1;
  background-color: $uds-neutral-600;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.08);

  .icon:hover {
    cursor: pointer;
  }

  .icon {
    &.disabled {
      opacity: 0.7;
    }
  }

  .percent {
    font-size: 12px;
    font-weight: $uds-weight-450;

    display: flex;
    align-items: center;
    width: initial;
    padding: $uds-spacing-0_5 $uds-spacing-1;
    background-color: $uds-white;
  }
}
</style>
